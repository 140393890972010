<template>
  <v-card style="margin: 20px; padding: 20px" class="card rounded-xl">
    <v-breadcrumbs style="margin-left: 15px;">
      <v-breadcrumbs-item to="/:tenant/settings" color="primary">{{ $t("settingsConversationTypes.settings")
        }}</v-breadcrumbs-item> >
      <v-breadcrumbs-item>{{ $t("settingsConversationTypes.coaching") }}</v-breadcrumbs-item>
    </v-breadcrumbs>

    <h1 style="text-align: left; margin-left: 30px;padding-bottom: 15px">{{ $t("settingsConversationTypes.title") }}
    </h1>

    <v-alert type="info" color="backgroundsecondary" style="text-align: left; margin-left: 25px; margin-bottom: 30px;">
      {{ $t("settingsConversationTypes.description") }}
    </v-alert>

    <div style="display: flex; margin-left: 30px; text-align: left;">
      <div style="display: flex; flex-direction: column; max-width: 300px; text-align: left;">
        <h3 style="padding-bottom: 10px; width: 300px; min-height: 40px;">{{
          $t("settingsConversationTypes.yourCallTypes") }}</h3>
        <v-btn variant="tonal" color="primary" @click="addNewField"
          style="margin-bottom: 10px; margin-right: 20px; margin-left: 0;">{{ $t("settingsConversationTypes.add")
          }}</v-btn>
      </div>

      <div style="display: flex; flex-direction: column; max-width: 300px; margin-left: 30px;">
        <h3 v-if="(isSaved || isFetched)" style="padding-bottom: 10px; width: 300px; min-height: 80px;">{{
          $t("settingsConversationTypes.yourScorecards") }}
        </h3>
      </div>
    </div>

    <!-- old and works -->
    <!-- <div v-for="(field, index) in fields" :key="index" style="margin-left: 30px; display: flex; align-items: center; gap: 20px;">
      <v-text-field 
        variant="solo" 
        style="max-width: 300px;" 
        v-model="fields[index].name" 
        placeholder="z.b. Jahresgespräch, Vorsorgeberatung, ...">
      </v-text-field> -->

    <!-- new and test -->
    <!-- <div v-for="(field, index) in fields" :key="index" style="margin-left: 30px; display: flex; align-items: center; gap: 20px;"> -->
    <div v-for="(field, index) in fields" :key="index" class="field-container">
      <v-text-field variant="solo" style="max-width: 300px;" v-model="fields[index].name"
        placeholder="z.b. Jahresgespräch, Vorsorgeberatung, ...">
      </v-text-field>
      <!-- Trash can icon for deleting a field -->
      <v-btn icon @click="confirmDelete(index)" class="delete-btn">
        <v-icon flat color="#9197B3">mdi-delete</v-icon>
      </v-btn>
      <v-btn :disabled="!(isSaved || isFetched)" v-if="!fields[index].associated_scorecard" color="#9197B3"
        style="margin-left: 20px; color:white" @click="chooseScorecard(index)">
        {{ $t("settingsConversationTypes.selectScorecard") }}
      </v-btn>
      <!-- btn to edit associated scorecard -->
      <v-btn v-if="fields[index].associated_scorecard"
        :to="`/${tenant}/scorecards/${fields[index].associated_scorecard}`"
        style="width: 300px; text-align: left; color: #9197b3; margin-left: 20px" text>
        <v-icon left>
          mdi-pencil
        </v-icon>

        <span class="truncate">{{ getScorecardNameById(fields[index].associated_scorecard) }}</span>

        <!-- {{ getScorecardNameById(fields[index].associated_scorecard) }} -->
      </v-btn>
      <v-btn variant="text" color="#9197B3" style="margin-left: 20px;" v-if="fields[index].associated_scorecard"
        @click="chooseScorecard(index)">
        <v-icon left>mdi-pencil</v-icon>
        
        {{ $t("settingsConversationTypes.otherScorecard") }}
      </v-btn>
    </div>
    <div style="margin-left: 15px; text-align: left;">
      <v-btn color="primary" @click="saveCallTypes" style="margin: 20px;">{{ $t("settingsConversationTypes.save")
        }}</v-btn>
    </div>
  </v-card>
  <!-- start test -->
  <v-dialog v-model="deleteDialog" persistent max-width="600px">
    <v-card>
      <v-card-title class="text-h5">{{ $t("settingsConversationTypes.deleteCallType") }}</v-card-title>
      <v-card-text>{{ $t("settingsConversationTypes.deleteCallTypeConfirm") }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="deleteDialog = false">{{ $t("settingsConversationTypes.cancel")
          }}</v-btn>
        <v-btn color="blue darken-1" text @click="deleteField">{{ $t("settingsConversationTypes.confirm") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- end test -->
</template>

<script>
import { ref, onMounted, provide } from 'vue';
import { supabase, userState } from '../supabase';
import { useRouter } from 'vue-router';
import { useRoute } from 'vue-router';
import { useUser } from '../useUser';

export default {
  setup() {
    const router = useRouter();
    const { user } = useUser();
    const fields = ref([{ name: '', associated_scorecard: null }]);
    const selectedField = ref('');
    const isSaved = ref(false);
    const isFetched = ref(false);
    const scorecards = ref([]);
    const route = useRoute();
    const tenant = route.params.tenant; // get the tenant from the route parameters

    // new
    const deleteDialog = ref(false);
    const deleteIndex = ref(null);

    const confirmDelete = (index) => {
      deleteIndex.value = index;
      deleteDialog.value = true;
    }

    provide('selectedField', selectedField);

    onMounted(async () => {
      const { data, error } = await supabase
        .from('conversationtypes')
        .select('name, associated_scorecard')
        .eq('user_id', user.value.id);
      if (error) {
        console.error('Error fetching call types:', error);
      } else if (!data || data.length === 0) {
        console.error('No scorecards returned');
      } else {
        // fields.value = data.map(item => item.name);
        fields.value = data.map(item => ({ name: item.name, associated_scorecard: item.associated_scorecard }));
        isFetched.value = true;
      }

      const { data: scorecardsData, error: scorecardsError } = await supabase
        .from('scorecards')
        .select('*');
      if (scorecardsError) {
        console.error('Error fetching scorecards:', scorecardsError);
      } else {
        scorecards.value = scorecardsData;
      }
    });

    const getScorecardNameById = (id) => {
      const scorecard = scorecards.value.find(scorecard => scorecard.id === id);
      return scorecard ? scorecard.name : '';
    };

    const addNewField = () => {
      fields.value.push({ name: '', associated_scorecard: null });
    };

    const saveCallTypes = async () => {
      const user = userState.currentUser;
      if (!user) throw new Error('User not found')

      // Check if any field has a non-empty name
      const hasPopulatedField = fields.value.some(field => field.name.trim() !== '');
      if (!hasPopulatedField) {
        console.error('No field has been populated');
        // Handle the error as needed, e.g. show a message to the user
        return;
      }
      // This is how you filter an array column to match a specific uuid
      const { data: organisations, error: orgError } = await supabase
        .from('organisations')
        .select('id')
        .contains('associated_user_ids', [user.id])
      if (orgError) throw orgError
      const organisationId = organisations[0].id;

      // Fetch all existing call types
      const { data: existingCallTypes, error: fetchError } = await supabase
        .from('conversationtypes')
        .select('name')
        .eq('user_id', user.id);
      if (fetchError) {
        console.error('Error fetching call types:', fetchError);
      }
      // Map existingCallTypes to an array of strings
      const existingCallTypeNames = existingCallTypes.map(callType => callType.name);
      // Filter out the call types that are already in the database
      const newCallTypes = fields.value.filter(field => !existingCallTypeNames.includes(field.name) && field.name.trim() !== '');

      // Save the new call types
      for (const field of newCallTypes) {
        console.log('saving field', field)
        const { error } = await supabase
          .from('conversationtypes')
          .insert([{ name: field.name, user_id: user.id, associated_organisation_id: organisationId }]);
        if (error) {
          console.error('Error saving call type:', error);
        }
      }
      isSaved.value = true;
    };

    const chooseScorecard = (index) => {
      selectedField.value = fields.value[index];
      router.push({ name: 'scorecards', query: { selectedFieldName: selectedField.value.name } });
    };

    const deleteField = async () => {
      if (deleteIndex.value !== null) {
        // Call Supabase to delete the field based on the index or some unique identifier
        const fieldToDelete = fields.value[deleteIndex.value];
        await supabase
          .from('conversationtypes')
          .delete()
          .match({ name: fieldToDelete.name, user_id: user.value.id });

        // Remove the field from the UI
        fields.value.splice(deleteIndex.value, 1);
        deleteDialog.value = false;
        deleteIndex.value = null;
      }
    };

    return {
      fields,
      addNewField,
      saveCallTypes,
      chooseScorecard,
      isSaved,
      isFetched,
      getScorecardNameById,
      tenant,
      deleteDialog,
      confirmDelete,
      deleteField,
    };
  },
};
</script>

<style scoped>
.scorecardtable {
  width: 100%;
  margin-left: 15px;
  margin-bottom: 30px;
}

.field-container {
  margin-left: 30px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>